.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.project-card {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px); /* Adjust as needed for desired layout */
}

.project-card h2 {
  margin-top: 0;
}

.project-card img {
  max-width: 100%;
  border-radius: 5px;
}

.project-card p {
  margin-bottom: 10px;
}

.project-card a {
  color: #007bff;
  text-decoration: none;
}

.project-card a:hover {
  text-decoration: underline;
}


@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
  background-color: #fdfdfd;
  font-family: 'Lato', Verdana, Helvetica, sans-serif;
  font-size: 14px;
}
#nav-header {
  border-top: 0px solid !important;
}
#nav-header a:hover {
  text-decoration: none;
}
#nav-site-title {
  font-weight: 500;
}
#nav-container {
  height: 3.5rem;
  font-size: 1.1rem;
  font-weight: 400;
}
@media (max-width: 991px) {
  #nav-container {
    font-size: 1.1rem;
  }
}
@media (max-width: 991px) {
  #nav-menu-container {
    position: absolute;
    z-index: 100;
    top: 0.4375rem;
    right: 1rem;
    border: 1px solid #e9ecef;
    border-radius: 0.25rem;
    background-color: #fdfdfd;
  }
}
#nav-menu-container a {
  color: #363636;
}
#nav-menu-container a:hover {
  color: #1f14f5;
}
#nav-trigger:checked ~ #nav-menu {
  display: block !important;
}
#content h1, #content h2, #content h3, #content h4 {
  margin-top: 0rem;
  margin-bottom: 0.4rem;
  text-align: left;
}
#content blockquote {
  border-left: 4px solid #e9ecef;
  padding-left: 1rem;
  font-style: italic;
}
#content .footnotes {
  text-align: left;
}

.highlight pre {
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
  padding: 0.75rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
  }
  .avatar {
  display: block;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #ddd;
  float: right;
  width: 250px;
  top: 1rem;
  bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  transition: 0.5s all ease-in-out;
  }
  @media (max-width: 991px) {
  .avatar {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
  .avatar:hover {
    transform: scale(1.05) rotate(2deg);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  @media (max-width: 991px) {
    .avatar:hover {
      transform: rotate(2deg);
    }
  }
  .icenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 991px) {
    .icenter {
      width: 100%;
    }
  }
  .textwrapper__image {
    width: 600px;
    float: left;
    margin: 0 40px 0 0;
  }
  .textwrapper__text {
    display: inline;
  }
  @media (max-width: 767px) {
    /* Apply img-fluid class to images on screens smaller than 'md' (mobile devices) */
    .mobile-img-fluid {
      max-width: 70%;
      height: auto;
    }
  }
  @media (min-width: 768px) {
    /* Apply img-fluid class to images on screens smaller than 'md' (mobile devices) */
    .mobile-img-fluid {
      max-width: 60%;
      height: auto;
    }
  }
  .main-topic::after {
    content: "";
    display: block;
    clear: both;
  }
  .right-text {
    width: 50%;
    float: right;
  }
  .left-picture {
    float: left;
    width: 50%;
  }
  .left-picture img {
    max-width: 100%;
  }
  .right-profile-text {
    width: 80%;
    float: right;
  }
  .left-profile-picture {
    float: left;
    width: 16.66%;
  }
  .left-profile-picture img {
    max-width: 100%;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.35rem;
  }
  h3 {
    font-size: 1.15rem;
  }
  h4, h5, h6 {
    font-size: 1.1rem;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
  h2:before {
    display: block;
    height: 5rem;
    margin-top: -5rem;
    visibility: hidden;
    content: "";
  }
  a {
    color: #1f14f5;
    text-decoration: none;
  }
  a:hover {
    color: #1f14f5;
  }
  #site-footer {
    font-size: 90%;
    padding-top: 0.1em;
    margin-top: 2em;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    text-align: center;
    color: #c0c0c0;
  }
  .navbar-toggler-icon {
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
    content: "menu";
  }
  .alert {
    padding: 0.75rem 2rem;
    font-size: 0.96em;
  }
  .alert-success {
    line-height: 1.8em;
    background-color: #1f14f5;
    color: #212121;
  }
  .alert-primary {
    line-height: 1.8em;
    color: #212121;
  }
  .btn-sm {
    padding: 0rem 0.5rem;
  }
  .highlight .c {
    color: #998;
    font-style: italic;
  }
  .highlight .err {
    color: #a61717;
    background-color: #e3d2d2;
  }
  .highlight .k {
    font-weight: bold;
  }
  .highlight .o {
    font-weight: bold;
  }
  .highlight .cm {
    color: #998;
    font-style: italic;
  }
  .highlight .cp {
    color: #999;
    font-weight: bold;
  }
  .highlight .c1 {
    color: #998;
    font-style: italic;
  }
  .highlight .cs {
    color: #999;
    font-weight: bold;
    font-style: italic;
  }
  .highlight .gd {
    color: #000;
    background-color: #fdd;
  }
  .highlight .gd .x {
    color: #000;
    background-color: #faa;
  }
  .highlight .ge {
    font-style: italic;
  }
  .highlight .gr {
    color: #a00;
  }
  .highlight .gh {
    color: #999;
  }
  .highlight .gi {
    color: #000;
    background-color: #dfd;
  }
  .highlight .gi .x {
    color: #000;
    background-color: #afa;
  }
  .highlight .go {
    color: #888;
  }
  .highlight .gp {
    color: #555;
  }
  .highlight .gs {
    font-weight: bold;
  }
  .highlight .gu {
    color: #aaa;
  }
  .highlight .gt {
    color: #a00;
  }
  .highlight .kc {
    font-weight: bold;
  }
  .highlight .kd {
    font-weight: bold;
  }
  .highlight .kp {
    font-weight: bold;
  }
  .highlight .kr {
    font-weight: bold;
  }
  .highlight .kt {
    color: #458;
    font-weight: bold;
  }
  .highlight .m {
    color: #099;
  }
  .highlight .s {
    color: #d14;
  }
  .highlight .na {
    color: #008080;
  }
  .highlight .nb {
    color: #0086b3;
  }
  .highlight .nc {
    color: #458;
    font-weight: bold;
  }
  .highlight .no {
    color: #008080;
  }
  .highlight .ni {
    color: #800080;
  }
  .highlight .ne {
    color: #900;
    font-weight: bold;
  }
  .highlight .nf {
    color: #900;
    font-weight: bold;
  }
  .highlight .nn {
    color: #555;
  }
  .highlight .nt {
    color: #000080;
  }
  .highlight .nv {
    color: #008080;
  }
  .highlight .ow {
    font-weight: bold;
  }
  .highlight .w {
    color: #bbb;
  }
  .highlight .mf {
    color: #099;
  }
  .highlight .mh {
    color: #099;
  }
  .highlight .mi {
    color: #099;
  }
  .highlight .mo {
    color: #099;
  }
  .highlight .sb {
    color: #d14;
  }
  .highlight .sc {
    color: #d14;
  }
  .highlight .sd {
    color: #d14;
  }
  .highlight .s2 {
    color: #d14;
  }
  .highlight .se {
    color: #d14;
  }
  .highlight .sh {
    color: #d14;
  }
  .highlight .si {
    color: #d14;
  }
  .highlight .sx {
    color: #d14;
  }
  .highlight .sr {
    color: #009926;
  }
  .highlight .s1 {
    color: #d14;
  }
  .highlight .ss {
    color: #990073;
  }
  .highlight .bp {
    color: #999;
  }
  .highlight .vc {
    color: #008080;
  }
  .highlight .vg {
    color: #008080;
  }
  .highlight .vi {
    color: #008080;
  }
  .highlight .il {
    color: #099;
  }
